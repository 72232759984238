import React, { useState } from 'react';
import './Modal.css';

const Modal = ({ show, onClose }) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch('https://utl01rfqwg.execute-api.us-east-2.amazonaws.com/help/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({ "email": email }),
      });
      if (response.ok) {
        onClose();
      } else {
        alert('An error occurred');
      }
    } catch (error) {
      alert('An error occurred');
    } finally {
      setLoading(false);
    }
  };

  if (!show) {
    return null;
  }

  const handleOverlayClick = (e) => {
    if (e.target.className === 'modal-overlay') {
      onClose();
    }
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content">
        <div className="modal-title-bar">
          <span className="modal-title">newsletter</span>
          <div>
            <button onClick={onClose} className="exit-button">-</button>
            <button onClick={onClose} className="exit-button">X</button>
          </div>
        </div>
        <h2>Subscribe to Vayda's Newsletter</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="submit" disabled={loading}>
            {loading ? 'Subscribing...' : 'Subscribe'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Modal;
